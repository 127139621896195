import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../assets'
import './style.scss'

const {
	garageBandSettingsAudioMidi,
	garageBandCreateNewTrackMicOrLine,
	garageBandAudio1PlugIns,
	garageBandCreateNewTrackMidi,
	garageBandSoftwareInstrumentGuitar,
	garageBandTranskrV2,
  logicProAudioDevices,
	logicProSettingsAudioDevices,
  logicProSettingsMidiInputs,
	logicProCreateNewTrackAudio,
	logicProTrackAudio1,
	logicProCreateNewTrackMidi,
	logicProTrackClassicClean,
	logicProAudio1TranskrPlugIn,
  flStudioAudioInput,
  flStudioFxSlot,
  flStudioMidiInput,
  flStudioTranskrV2,
  flStudioChannel,
  flStudioAsioPanel,
  bitwigStudioSettingsAudio,
  bitwigStudioSettingsControllers,
  bitwigStudioMixerAudio2,
  bitwigStudioMixerInst1,
  bitwigStudioTranskrV2,
  reaperPreferencesAudioDevice,
  reaperPreferencesAudioMidiInputs,
  reaperAddFxToTrack1,
  reaperFxTrack2,
  reaperTranskrV2,
  tunerImage,
  fretboardGui
} = images

const userGuideView = () => {
	window.gtag_sendEvent('user_guide')
}

const UserGuide = () => {
  useEffect(() => {
    userGuideView()
  }, [])

  const supportUrl = 'mailto:support@algoriffix.com?subject=Transkr%20V2%20Support&body=Please%20describe%20the%20issue%20you%20are%20experiencing...'

  return (
    <section id='user-guide'>
      <Container className='user-guide-text'>
        <h2>User Guide</h2>
        <p>Last updated: August 20, 2024</p>
        <p>
          Every instrument has a unique sound, and every player has their own technique to shape that sound. 
          The extraordinary happens when the two elements come together to fuse in an emotional high. 
          The auditory experience of such a sonic entanglement can vary a lot, and this is particularly true for the guitarist. 
          It is therefore practically impossible to collect enough data that is representative of all instruments, all players, 
          all techniques, and all recording environments to train an algorithm that would be able to decipher the message hidden in the music, 
          not to mention the resources such an amount of data would require for storage and processing. 
          To nevertheless provide you with a practical solution, we at <a href='/'>Algoriffix</a> have approached the problem from a different angle: 
          We have spent years of research to understand the psychoacoustic phenomena behind pitch perception and its physical correlates 
          and have studied how machine learning algorithms &quot;see&quot; pitch. 
          Based on our findings, we have broken down the problem into its basic elements and 
          developed a tool that gives you the freedom to parametrise a model that works best for you and 
          your instrument. <b>No cloud, no copyright infringement.</b> This tool is called <a href='/'>Transkr V2</a>.
        </p>
        <ul className='toc'>
          <li>
            <a href="#installation"><span className='title'>Installation</span></a>
            <ul>
              <li><a href='#installation-macos'>macOS</a></li>
              <li><a href='#installation-windows'>Windows</a></li>
            </ul>
          </li>
          <li>
            <a href="#quick-setup">Quick Setup</a>
            <ul>
              <li><a href='#quick-setup-garageband'>GarageBand</a></li>
              <li><a href='#quick-setup-logic-pro'>Logic Pro</a></li>
              <li><a href='#quick-setup-fl-studio'>FL Studio</a></li>
              <li><a href='#quick-setup-bitwig-studio'>Bitwig Studio</a></li>
              <li><a href='#quick-setup-reaper'>REAPER</a></li>
            </ul>
          </li>
          <li>
            <a href="#usage">Usage</a>
            <ul>
              <li><a href='#customisation'>Model Customisation and Fine-Tuning</a></li>
              <li><a href='#audio-to-midi'>Audio to MIDI</a></li>
            </ul>
          </li>
          <li><a href="#fretboard">Fretboard</a></li>
          <li><a href="#license-key">License Key</a></li>
          <li><a href="#technical-support">Technical Support</a></li>
        </ul>
        <h3 id='installation'>Installation</h3>
        <h4 id='installation-macos'>macOS</h4>
        <ol>
          <li>Download the archive (ZIP) file</li>
          <li>Open the ZIP file</li>
          <li>Run the Transkr V2 installer (.pkg)</li>
        </ol>
        <p>
          <strong>Important</strong>
        </p>
        <div className='important-box'>
          <p>
            For Transkr V2 to send MIDI data to your DAW, you need to enable the IAC Driver and set up at least one bus in the IAC Driver. 
            You can run several instances of Transkr V2 at the same time. 
            In such a case, we recommend using a separate bus for each instance. 
            Please refer to Apple's <a href='https://support.apple.com/guide/audio-midi-setup/welcome/mac' target='_blank' rel='noreferrer'>
              Audio MIDI Setup User Guide
            </a> for more details. 
            You can find the exact sequence of steps under &quot;Transfer MIDI information between apps in Audio MIDI Setup on Mac.&quot;
          </p>
          <p>
            If you are a GarageBand or Logic Pro user, you can also use a virtual MIDI device called &quot;GarageBand Virtual In&quot; or 
            &quot;Logic Pro Virtual In&quot; to receive and record MIDI messages. 
            You will find more information in the <a href='https://support.apple.com/guide/logicpro/welcome/10.8/mac' target='_blank' rel='noreferrer'>
              Logic Pro User Guide for Mac
            </a> under &quot;Receive MIDI messages from another music app in Logic Pro for Mac.&quot; 
            Double check your MIDI configuration should you fail to receive any MIDI data.
          </p>
          <p>
            Transkr V2 is an effects plug-in and should be used as such. 
            If, after the installation, you cannot see it listed in your DAW, please have a look at Apple's recommendations, 
            which you can find <a href='https://support.apple.com/en-us/109315' target='_blank' rel='noreferrer'>here</a>.
          </p>
          <p>
            Should you get a warning dialog trying to open the standalone app, 
            have a look <a href='https://support.apple.com/guide/mac-help/open-a-mac-app-from-an-unidentified-developer-mh40616/mac' target='_blank' rel='noreferrer'>here</a> for help.
          </p>
        </div>
        <h4 id='installation-windows'>Windows</h4>
        <ol>
          <li>Download the archive (ZIP) file</li>
          <li>Open the ZIP file</li>
          <li>Run the Transkr V2 installer (.exe)</li>
        </ol>
        <p>
          <strong>Important</strong>
        </p>
        <div className='important-box'>
          <p>
            For Transkr V2 to send MIDI data to your DAW, you need to have a virtual loopback device with a MIDI port available on your system, 
            such as <a href='https://www.tobias-erichsen.de/software/loopmidi.html' target='_blank' rel='noreferrer'>loopMIDI</a>. 
            You can also use the Microsoft GS Wavetable Synth, although we do not recommend it beyond testing. 
            You can run several instances of Transkr V2 at the same time. 
            In such a case, we suggest using a separate port for each instance.
          </p>
          <p>
            Transkr V2 is an effects plug-in and should be used as such. 
            If, after the installation, you cannot see it listed in your DAW, 
            try searching in the folder <tt>%COMMONPROGRAMFILES%\VST3</tt>, 
            typically <tt>C:\Program Files\Common Files\VST3</tt>, 
            from the plug-in manager. 
            In some cases, it may be necessary to explicitly set the plug-in type to &quot;Effect&quot;.
          </p>
          <p>
            Make sure you have the latest supported <a href='https://learn.microsoft.com/en-us/cpp/windows/latest-supported-vc-redist' target='_blank' rel='noreferrer'>Microsoft Visual C++ Redistributable</a> installed. 
            You can download the x64 version using this <a href='https://aka.ms/vs/17/release/vc_redist.x64.exe' download>link</a>. Otherwise, Transkr V2 may not launch.
          </p>
          <p>
            Should Windows Security prevent the standalone app from starting, 
            have a look <a href='https://support.microsoft.com/en-us/windows/add-an-exclusion-to-windows-security-811816c0-4dfd-af4a-47e4-c301afe13b26' target='_blank' rel='noreferrer'>here</a> for help.
          </p>
        </div>
        <h3 id='quick-setup'>Quick Setup</h3>
        <p>
          Thank you for your interest in Transkr V2. Follow these steps to quickly set up the plug-in in your DAW:
          <ul>
            <li>Set up your audio interface</li>
            <li>Make sure a virtual MIDI device is available</li>
            <li>Insert Transkr V2 into the effects rack with input from your audio interface</li>
            <li>Select the virtual MIDI device in Transkr V2 as MIDI output</li>
            <li>Insert a virtual instrument that receives MIDI data from the virtual MIDI device</li>
          </ul>
          For more details, continue reading.
        </p>
        <h4 id='quick-setup-garageband'>GarageBand</h4>
        <p>
          Here, you can see how to set up Transkr V2 in GarageBand on a Mac.
        </p>
        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the Audio MIDI Setup app and select your external sound card (audio interface). 
          Set the sampling rate to 48 kHz.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={logicProAudioDevices} alt='Audio Devices' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the audio/MIDI settings and select your external sound card (audio interface) as the audio device. 
          Make sure that there is at least one MIDI input detected &mdash; two, if you enabled the IAC Driver.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={garageBandSettingsAudioMidi} alt='Settings: Audio/MIDI' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Create an audio track and choose the mono input from your audio interface with your mic or instrument connected as the audio input.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={garageBandCreateNewTrackMicOrLine} alt='Create New Track: Mic or Line' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Select the audio track and insert Transkr V2 as an audio FX plug-in.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={garageBandAudio1PlugIns} alt='Audio 1: Plug-ins' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Create a MIDI track with a software instrument for audio output.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={garageBandCreateNewTrackMidi} alt='Create New Track: MIDI' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 6</strong>
        </p>
        <p>
          Select a synthesiser or any virtual instrument of your choice from your library. 
          The selected instrument will receive MIDI data from the MIDI input port.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={garageBandSoftwareInstrumentGuitar} alt='Software Instrument: Guitar' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 7</strong>
        </p>
        <p>
          Open Transkr V2 and
          <ul>
            <li>
              Set LINE INPUT to the channel associated with your audio input
            </li>
            <ul>
              <li>
                Make sure the VU meter reacts to your input and reaches 100% without saturating
              </li>
              <li>
                Use the GAIN knob on your audio interface and/or the plug-in to adjust the input level
              </li>
              <li>
                Use the &quot;Pass thru&quot; button to verify that your input is free of clicks and pops
              </li>
            </ul>
            <li>
              Set MIDI OUTPUT to the MIDI input port that shall receive MIDI data from the plug-in
            </li>
            <li>
              Select one of the defaults from the PRESET list that best represents your instrument
            </li>
          </ul>
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={garageBandTranskrV2} alt='Transkr V2' fluid />
            </Col>
          </Row>
        </p>
        <p>
          You should now be all set to start using Transkr V2 in GarageBand.
        </p>
        <h4 id='quick-setup-logic-pro'>Logic Pro</h4>
        <p>
          Here, you can see how to set up Transkr V2 in Logic Pro on a Mac.
        </p>
        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the Audio MIDI Setup app and select your external sound card (audio interface). 
          Set the sampling rate to 48 kHz.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={logicProAudioDevices} alt='Audio Devices' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the audio settings and select your external sound card (audio interface) as the audio device. 
          Set the buffer size to 128 samples. 
          Going below 128 is not recommended, as this may result in underruns and negatively impact the audio quality.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={logicProSettingsAudioDevices} alt='Settings: Audio Devices' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Open the MIDI settings and make sure that the MIDI port "IAC Driver Bus" is enabled as input.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={logicProSettingsMidiInputs} alt='Settings: MIDI Inputs' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Create an audio track and choose the mono input from your audio interface with your mic or instrument connected as the audio input.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={logicProCreateNewTrackAudio} alt='Create Audio Track' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Select the audio track and insert Transkr V2 as an audio FX plug-in.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={logicProTrackAudio1} alt='Audio Track' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 6</strong>
        </p>
        <p>
          Create a MIDI track with a software instrument for audio output.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={logicProCreateNewTrackMidi} alt='Create MIDI Track' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 7</strong>
        </p>
        <p>
          Select a synthesiser or any virtual instrument of your choice from your library. 
          The selected instrument will receive MIDI data from the MIDI input port.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={logicProTrackClassicClean} alt='MIDI Track' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 8</strong>
        </p>
        <p>
          Open Transkr V2 and
          <ul>
            <li>
              Set LINE INPUT to the channel associated with your audio input
            </li>
            <ul>
              <li>
                Make sure the VU meter reacts to your input and reaches 100% without saturating
              </li>
              <li>
                Use the GAIN knob on your audio interface and/or the plug-in to adjust the input level
              </li>
              <li>
                Use the &quot;Pass thru&quot; button to verify that your input is free of clicks and pops
              </li>
            </ul>
            <li>
              Set MIDI OUTPUT to the MIDI input port that shall receive MIDI data from the plug-in
            </li>
            <li>
              Select one of the defaults from the PRESET list that best represents your instrument
            </li>
          </ul>
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={logicProAudio1TranskrPlugIn} alt='Transkr V2' fluid />
            </Col>
          </Row>
        </p>
        <p>
          You should now be all set to start using Transkr V2 in Logic Pro.
        </p>
        <h4 id='quick-setup-fl-studio'>FL Studio</h4>
        <p>
          Here, you can see how to set up Transkr V2 in FL Studio. 
          The screenshots were taken on a Windows PC, but the steps are essentially the same on a Mac.
        </p>
        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the audio settings (F10) and select your external sound card (audio interface) as the audio device. 
          We recommend using the drivers from the manufacturer, as they are most reliable and have the lowest latency. 
          Set the sampling rate to 48 kHz and the buffer size to 128 samples. 
          Going below 128 is not recommended, since this may result in underruns and negatively impact the audio quality.
        </p>
        <p>
          <Row className='justify-content-md-center'>
            <Col lg={8} className='text-center'>
              <Image src={flStudioAudioInput} alt='Audio Device' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the MIDI settings (F10) and enable &quot;loopMIDI Port&quot; on Windows or &quot;IAC Driver Bus&quot; on Mac, 
          which should be listed as one of the MIDI inputs.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={10} className='text-center'>
            <Image src={flStudioMidiInput} alt='MIDI Input' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Select a free mixer track, 
          choose the mono input from your audio interface with your mic or instrument connected as the external audio input source, 
          and insert Transkr V2 into an empty FX slot.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={flStudioFxSlot} alt='Mixer FX Slot' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Open the channel rack and add a synthesiser or any virtual instrument of your choice for audio output. 
          The selected instrument will receive MIDI data from the MIDI input port.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={flStudioChannel} alt='Virtual Instrument' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Open Transkr V2 and
          <ul>
            <li>
              Set LINE INPUT to the channel associated with your audio input (only relevant for stereo input)
            </li>
            <ul>
              <li>
                Make sure the VU meter reacts to your input and reaches 100% without saturating
              </li>
              <li>
                Use the GAIN knob on your audio interface and/or the plug-in to adjust the input level
              </li>
              <li>
                Use the &quot;Pass thru&quot; button to verify that your input is free of clicks and pops
              </li>
            </ul>
            <li>
              Set MIDI OUTPUT to the MIDI input port that shall receive MIDI data from the plug-in
            </li>
            <li>
              Select one of the defaults from the PRESET list that best represents your instrument
            </li>
          </ul>
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={flStudioTranskrV2} alt='Transkr V2' fluid />
            </Col>
          </Row>
        </p>
        <p>
          You should now be all set to start using Transkr V2 in FL Studio.
        </p>
        <h4 id='quick-setup-bitwig-studio'>Bitwig Studio</h4>
        <p>
          Here, you can see how to set up Transkr V2 in Bitwig Studio. 
          The screenshots were taken on a Windows PC, but the steps are essentially the same on a Mac.
        </p>
        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the audio tab in settings and select your external sound card (audio interface) as the audio device. 
          We recommend using the drivers from the manufacturer, as they are most reliable and have the lowest latency. 
          Set the sampling rate to 48 kHz and the buffer size to 128 samples. 
          Going below 128 is not recommended, since this may result in underruns and negatively impact the audio quality.
        </p>
        <p>
          <Row className='justify-content-md-center'>
            <Col lg={8} className='text-center'>
              <Image src={bitwigStudioSettingsAudio} alt='Settings: Audio' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the controllers tab in settings and add a generic MIDI keyboard.  
          Select &quot;loopMIDI Port&quot; on Windows or &quot;IAC Driver Bus&quot; on Mac as MIDI input.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={bitwigStudioSettingsControllers} alt='Settings: Controllers' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Select the audio track in the mixer, 
          choose the input from your audio interface with your mic or instrument connected as the external audio input source, 
          and insert Transkr V2 into the device chain. Activate the audio engine and make sure the audio track is armed for recording.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={bitwigStudioMixerAudio2} alt='Audio Track' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Select the instrument track in the mixer and insert a synthesiser or any virtual instrument of your choice into the device chain for audio output. 
          The selected instrument will receive MIDI data from the MIDI input port.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={bitwigStudioMixerInst1} alt='Instrument Track' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Open Transkr V2 and
          <ul>
            <li>
              Set LINE INPUT to the channel associated with your audio input (only relevant for stereo input)
            </li>
            <ul>
              <li>
                Make sure the VU meter reacts to your input and reaches 100% without saturating
              </li>
              <li>
                Use the GAIN knob on your audio interface and/or the plug-in to adjust the input level
              </li>
              <li>
                Use the &quot;Pass thru&quot; button to verify that your input is free of clicks and pops
              </li>
            </ul>
            <li>
              Set MIDI OUTPUT to the MIDI input port that shall receive MIDI data from the plug-in
            </li>
            <li>
              Select one of the defaults from the PRESET list that best represents your instrument
            </li>
          </ul>
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={bitwigStudioTranskrV2} alt='Transkr V2' fluid />
            </Col>
          </Row>
        </p>
        <p>
          You should now be all set to start using Transkr V2 in Bitwig Studio.
        </p>
        <h4 id='quick-setup-reaper'>REAPER</h4>
        <p>
          Here, you can see how to set up Transkr V2 in REAPER. 
          The screenshots were taken on a Windows PC, but the steps are essentially the same on a Mac.
        </p>
        <p>
          <strong>Step 1</strong>
        </p>
        <p>
          Open the audio settings and select your external sound card (audio interface) as the audio device. 
          We recommend using the drivers from the manufacturer, as they are most reliable and have the lowest latency. 
          Set the sampling rate to 48 kHz and the buffer size to 128 samples. 
          Going below 128 is not recommended, since this may result in underruns and negatively impact the audio quality.
        </p>
        <p>
          <Row className='justify-content-md-center'>
            <Col lg={10} className='text-center'>
              <Image src={reaperPreferencesAudioDevice} alt='Audio Device' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 2</strong>
        </p>
        <p>
          Open the MIDI settings and make sure that &quot;loopMIDI Port&quot; (Windows) or &quot;IAC Driver Bus&quot; (Mac) 
          is listed as one of the MIDI input devices.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={10} className='text-center'>
            <Image src={reaperPreferencesAudioMidiInputs} alt='MIDI Inputs' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 3</strong>
        </p>
        <p>
          Insert a new track, 
          choose the mono input from your audio interface with your mic or instrument connected as the external audio input source, 
          and add Transkr V2 to the FX chain.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={8} className='text-center'>
            <Image src={reaperAddFxToTrack1} alt='Add FX' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 4</strong>
        </p>
        <p>
          Insert a MIDI track and add a synthesiser or any virtual instrument of your choice for audio output. 
          The selected instrument will receive MIDI data from the MIDI input device.
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={12} className='text-center'>
            <Image src={reaperFxTrack2} alt='Virtual Instrument' fluid />
            </Col>
          </Row>
        </p>
        <p>
          <strong>Step 5</strong>
        </p>
        <p>
          Open Transkr V2 and
          <ul>
            <li>
              Set the line input to the channel associated with your audio input (only for stereo input)
            </li>
            <ul>
              <li>
                Make sure the VU meter reacts to your input and reaches 100% without saturating
              </li>
              <li>
                Use the gain knob on your audio interface and/or the plug-in to adjust the input level
              </li>
              <li>
                Use the &quot;Pass thru&quot; button to verify that your input audio is free of clicks and pops
              </li>
            </ul>
            <li>
              Set the MIDI output to the MIDI input device that shall receive MIDI data
            </li>
            <li>
              Select one of the defaults from the preset list that best represents your instrument
            </li>
          </ul>
        </p>
        <p>
          <Row className='justify-content-md-center'>
          <Col lg={10} className='text-center'>
            <Image src={reaperTranskrV2} alt='Transkr V2' fluid />
            </Col>
          </Row>
        </p>
        <p>
          You should now be all set to start using Transkr V2 in REAPER.
        </p>


        <h3 id='usage'>Usage</h3>
        <p>
          Here, you can read up on how to customise and fine-tune Transkr V2 and discover additional use cases.
        </p>
        <p>
          <strong>Important</strong>
        </p>
        <div className='important-box'>
          <p>
            Unless stated otherwise, your instrument should use standard tuning. 
            Making sure it is in tune with the algorithm will give you the best result. 
            Transkr V2 uses the MIDI Tuning Standard (MTS), including A440 with the A above middle C tuned to 440 Hz.
          </p>
          <p>
            <Row className='justify-content-md-center'>
              <Col lg={8} className='text-center'>
                <Image src={tunerImage} alt='Tuner' fluid />
              </Col>
            </Row>
          </p>
        </div>
        <h4 id='customisation'>Model Customisation and Fine-Tuning</h4>
        <p>
          It can be a bit tricky to find a model that works well with both single notes and chords, but it is feasible. 
          Should you encounter difficulties finding such a model, you may want to consider creating two different presets: 
          one for chords and one for melody. 
          Electric guitars, e.g., can produce such a thick sound when playing multiple notes at the same time 
          that the algorithm may generate more notes than expected. 
          These extra notes usually represent strong harmonics. 
          It is important to understand that the algorithm tries to explain the sound of your instrument with the model it has, 
          which you can customise and fine-tune. 
          You should start with a default preset for your instrument and then 
          experiment with the parameters that impact the pitch recognition to get a feel for the algorithm. 
          These parameters are:
          <ul>
            <li>Gain</li>
            <li>Harmonics</li>
            <li>Bass, mid and treble</li>
            <li>Sensitivity or melody, unison and legato in pro mode</li>
          </ul>
          <dl>
            <dt>Gain</dt>
            <dd>
              &hellip;helps you level the input signal and, by doing so, 
              to control the portion of the signal that undergoes pitch recognition. 
              You should avoid saturating the signal by too much.
            </dd>
            <dt>Harmonics</dt>
            <dd>
              &hellip;lets you adjust the harmonic strength of your instrument, 
              which is the main model parameter. 
              Most string instruments are rich in harmonics and 
              the optimum value is usually somewhere between 1.1 and 1.7.
            </dd>
            <dt>Bass, mid and treble</dt>
            <dd>
              &hellip;are the gain parameters of the built-in 3-band equalizer. 
              You should use them to re-shape the tone of your instrument, 
              neutralising harsh or resonant frequencies and/or 
              giving more presence to subtle frequency components.
            </dd>
            <dt>Sensitivity</dt>
            <dd>
              &hellip;helps you fine-tune your custom model in a straightforward way. 
              It is a joint control over the three following parameters, 
              which are accessible after clicking the &quot;Pro&quot; button.
            </dd>
            <dt>Melody</dt>
            <dd>
              &hellip;lets you reduce ghost notes. 
              These are undesired pitch artefacts that stem from picking or plucking, fret-hand movement, and wave interference.
            </dd>
            <dt>Unison</dt>
            <dd>
              &hellip;lets you reduce octave doubling. 
              These are undesired pitch artefacts that stem mostly from too strong second harmonics.
            </dd>
            <dt>Legato</dt>
            <dd>
              &hellip;lets you reduce jumpy notes. 
              These are undesired amplitude fluctuations that stem from interference effects between notes, including all the harmonics, and 
              resonance between notes and the instrument.
            </dd>
          </dl>
          The pitch recognition, especially polyphonic, is most sensitive to the instrument model, which includes harmonic strength and tone. 
          Start wiggling with these parameters, setting sensitivity to 1.0, to find a sweet spot. 
          A good custom model should correctly recognise the pitch of all open strings separately and the factors of all basic chords. 
          Setting the harmonics parameter to the lowest acceptable value will make sure that notes in the lower register do not absorb notes in the higher register. 
          If there are sometimes ghost notes appearing, do not worry, you can fine-tune later with unison. 
          Increasing the harmonics value can also be an alternative to reducing ghost notes, when playing single notes. 
          If notes in a frequency range are missing, try increasing the equaliser gain for that range. 
          If too many upper harmonics occur, decrease the treble gain, e.g. 
          If the velocity is weak on all notes, increase the gain on your audio interface and/or the gain parameter value. 
          The dynamics parameter affects only the velocity of note events, not the instrument model.
        </p>
        <h4 id='audio-to-midi'>Audio to MIDI</h4>
        <p>
          Listed below are the use cases for Transkr V2. If you know of others, please let us know.
        </p>
        <p>
          <strong>Real-time processing</strong>
        </p>
        <p>
          Once you have set up Transkr V2 in your DAW, you can start transcribing audio to MIDI by a simple click on the green button with the robot head. 
          The button will turn magenta on activation and the clock will start counting. 
          The harmonics parameter is inaccessible during operation, so you will need to click the button again to change it. 
          When active, the algorithm will recognise note patterns based on the custom model and convert them to MIDI events. 
          The plug-in will then pass the MIDI events on to your DAW via the selected MIDI output channel. 
          In addition, the algorithm tracks 
          <ul>
            <li>Level</li>
            <li>Tempo</li>
            <li>Key</li>
            <li>Chords</li>
            <li>Melody</li>
          </ul>
          The level is measured in dB RMS and the tempo in BPM. 
          The key can be major or (natural) minor. 
          Chords are represented by chord symbols and Roman numerals relative to the current key (when applicable), 
          while notes are displayed in scientific pitch notation (SPN).
        </p>
        <p>
          <strong>File-based processing</strong>
        </p>
        <p>
          You can also convert an existing audio recording of yours to Standard MIDI File (SMF) by means of drag and drop: 
          simply select a file (WAVE, AIFF or MP3) on your computer, 
          drag it to the Transkr V2 window, and drop it over the green button with the robot head. 
          This will initiate a new conversion procedure and you will be able to track its progress. 
          The algorithm will resort to the current settings during conversion. 
          On success, the resulting MIDI file will appear in the file manager, next to the original audio file. 
          You can expect better results from file-based forward-backward processing, 
          as opposed to forward-only processing in the real-time case. 
          You can also use the standalone application, which may be quicker, since latency does not matter here. 
          The MIDI file is comprised of the following tracks:
          <ul>
            <li>Time signature</li>
            <li>Beats</li>
            <li>Key signature</li>
            <li>Notes</li>
            <li>Chords</li>
            <li>Melody</li>
          </ul>
        </p>
        <p>
          Bear in mind that file-based audio-to-MIDI conversion may work only from the Downloads and the Music folders on a Mac.
        </p>
        <p>
          <strong>Web-based processing</strong>
        </p>
        <p>
          Although this was not our intention, we would like to mention the possibility to stream audio directly from the web and 
          run it through Transkr V2, or to record it to an audio file and convert the file to MIDI. 
          All you need for this is a virtual audio cable, such as <a href='https://vb-audio.com/Cable/' target='_blank' rel='noreferrer'>VB-CABLE</a>.
        </p>
        <p>
          In FL Studio, you would open the audio settings (F10), select FL Studio ASIO as the audio device, and open the ASIO panel. 
          You would then select the output from the virtual audio cable as the input and your default audio interface as the output. 
          Make sure to select the input from the virtual audio cable as the output in your system settings, so audio from the web gets routed to your DAW. 
          The rest is as usual.
        </p>
        <p>
          <Row className='justify-content-md-center'>
            <Col lg={8} className='text-center'>
              <Image src={flStudioAsioPanel} alt='FL Studio ASIO' fluid />
            </Col>
          </Row>
        </p>
        <p>
          The chord and melody recognition can work magic if the model fits and the modelled instrument dominates the mix. Try it for yourself!
        </p>
        <h3 id='fretboard'>Fretboard</h3>
        <p>
          The fretboard can be turned on and off when using the guitar as instrument. 
          There is an algorithm in place that will try to find a fingering based on the ringing notes and an estimate of the left-hand position. 
          The fingerings are meant to be &quot;easy&quot; to play. 
          If no fingering can be assigned, the last valid fingering is shown until all notes ring out.
          <p>
          <Row className='justify-content-md-center'>
            <Col lg={10} className='text-center'>
              <Image src={fretboardGui} alt='Fretboard GUI' fluid />
            </Col>
          </Row>
        </p>
        </p>
        <h3 id='license-key'>License Key</h3>
        <p>
          To unlock the full version, open Transkr V2 and click on the icon in the upper-right corner. 
          Then enter the license key.
        </p>
        <h3 id='technical-support'>Technical Support</h3>
        <p>
          Having trouble using or tuning Transkr V2? 
          Customers who purchased a license can contact <a href={supportUrl}>technical support</a>.
        </p>
      </Container>
    </section>
  )
}

export default UserGuide
