import React from 'react'
// import { useSelector } from 'react-redux'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import socialMediaList from '../../../constants/social_media'
// import { faFacebookF, faInstagram, faLinkedinIn, faSoundcloud, faYoutube } from '@fortawesome/free-brands-svg-icons'
import VideoPlayer from '../../VideoPlayer'
// import { transcription_url } from '../../../constants'
// import { authTokenSelector } from '../../../redux/auth'
import { svgs } from '../../../assets'
import { images } from '../../../assets'
import './style.scss'

const { /* macAppStoreBlack, */ macAppStoreWhite } = svgs
const { robotHead } = images

const explainerVideoUrl = 'https://vimeo.com/914194796/b4d2b42082'

const mailtoSubject = 'Hey,%20Algoriffix!'
const mailtoBody = 'I%20am%20interested%20in%20learning%20more%20about%20your%20technology!'

const contactUsUrl = `mailto:info@algoriffix.com?subject=${mailtoSubject}&body=${mailtoBody}`

// const buyButtonId = 'buy_btn_1P6iZFAb5CA4yTPS6snLMZfu'
// const publishableKey = 'pk_live_51HtWHFAb5CA4yTPSkHYYgRx5Qd8BgU8nzmhV24cuZIrbYHkZz2S6vZQai3BEYEYxyKhGnopJo8LiwyYytmRt4LXD00kOm79ikX'

const macAppStoreUrl = 'https://apps.apple.com/app/transkr-v2/id6605920845'

const macAppStoreClicked = () => {
	window.gtag_sendEvent('mac_app_store_click', {
		url: macAppStoreUrl
	})
}

const contactUsClicked = () => {
	window.gtag_sendEvent('contact_us_click', {
		url: contactUsUrl
	})

	window.fbq_standardEvent('Contact');
}

const explainerVideoPlayed = () => {
	window.gtag_sendEvent('explainer_video_play', {
		url: explainerVideoUrl
	})
}

const Banner = () => {
	const socialMediaItems = socialMediaList.map((item) =>
		<div className='social-media-i' key={item.name}>
			<a href={item.href} alt={item.name} target='_blank' rel='noreferrer' onClick={item.gtag}>
				<FontAwesomeIcon icon={item.icon} />
			</a>
		</div>
	)

	return (
		<section id='banner'>
			<Container>
				<Row className='align-items-center'>
					<Col className='col-lg-5 col-md-6 banner-text pb-contact'>
						<h2>
							AI As Your
							{' '}
							<nobr>
								Co-Writer&trade;
							</nobr>
						</h2>
						<h3>
							Play. Sing. Create.
						</h3>
						<p className='pt'>
							Unleash the power of your guitar solos with Transkr V2, the ultimate real-time audio-to-MIDI plug-in. 
							Seamlessly integrate it into your favourite DAW and transform your instrument into a MIDI controller in no time.
						</p>
						<div className='app-store-badge pb-3'>
							<a href={macAppStoreUrl} target='_blank' rel='noreferrer' onClick={macAppStoreClicked}><Image src={macAppStoreWhite} /></a>
						</div>
						<p className='pb-contact'>
							Get in touch if you wish to join us on our journey, or if you are interested in licensing our technology.
						</p>
						<a href={contactUsUrl} className='contact-us-button' onClick={contactUsClicked}>
							Contact Us
						</a>
						{/*
						<p>
							Welcome to a place for aspiring musicians, composers, and music producers &mdash; where you can find
							inspiration	for your creative work. Simply upload your unaccompanied solo or stem and let Algoriffix
							decipher the notes and recommend a harmony that fits your performance. Or let it improvise an original
							melody on top!
							<br />
							<br />
							Fast, reliable, and versatile. Our proprietary AI will unlock your hidden potential and
							help you express yourself within seconds.
						</p>
						*/}
						{/*
						<div className='row'>
							<div className='col-auto d-flex action-button'>
								<a href={transcription_url} role='button'>
									{session_token ? 'Goto AI' : 'Try Now'}
								</a>
							</div>
							<div className='col-auto d-flex'>
								<a
									href='https://play.google.com/apps/testing/com.algoriffix.jammin'
									target='_blank'
									rel='noreferrer'
									role='button'
								>
									<div className='g-play-button'>
										<i className='fab fa-google-play' aria-hidden />
									</div>
								</a>
							</div>
						</div>
						*/}
						{/*
						<div className='mt-5 text-center'>
							<stripe-buy-button
  							buy-button-id={buyButtonId}
  							publishable-key={publishableKey}
							/>
						</div>
						*/}
					</Col>
					<Col className='col-lg-7 col-md-6'>
						<div className='banner-img-after'>
							<div className='banner-img'>
								<Image src={robotHead} alt='Robot Head with Splash' fluid />
								<div className='image-overlay'>
									<VideoPlayer url={explainerVideoUrl} onPlay={explainerVideoPlayed}>
										<div className='image-button'>
											<FontAwesomeIcon icon={faPlay} />
										</div>
									</VideoPlayer>
								</div>
							</div>
						</div>
						<div className='social-media'>
							{socialMediaItems}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default Banner
