import { faFacebookF, faInstagram, faLinkedinIn, faSoundcloud, faYoutube } from "@fortawesome/free-brands-svg-icons"

const facebookUrl = 'https://www.facebook.com/Algoriffix'
const instagramUrl = 'https://www.instagram.com/algoriffix'
const linkedInUrl = 'https://www.linkedin.com/company/algoriffix'
const youTubeUrl = 'https://www.youtube.com/@algoriffix'
const soundCloudUrl = 'https://soundcloud.com/algoriffix'

const socialMediaClicked = (url) => window.gtag_sendEvent('social_media_click', {
  url: url
})

const facebookClicked = () => socialMediaClicked(facebookUrl)
const instagramClicked = () => socialMediaClicked(instagramUrl)
const linkedInClicked = () => socialMediaClicked(linkedInUrl)
const youTubeClicked = () => socialMediaClicked(youTubeUrl)
const soundCloudClicked = () => socialMediaClicked(soundCloudUrl)

const socialMediaList = [
  {
    name: 'Facebook',
    href: facebookUrl,
    icon: faFacebookF,
    gtag: facebookClicked
  },
  {
    name: 'Instagram',
    href: instagramUrl,
    icon: faInstagram,
    gtag: instagramClicked
  },
  {
    name: 'LinkedIn',
    href: linkedInUrl,
    icon: faLinkedinIn,
    gtag: linkedInClicked
  },
  {
    name: 'YouTube',
    href: youTubeUrl,
    icon: faYoutube,
    gtag: youTubeClicked

  },
  {
    name: 'SoundCloud',
    href: soundCloudUrl,
    icon: faSoundcloud,
    gtag: soundCloudClicked
  }
]

export default socialMediaList
