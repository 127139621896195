import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { images } from '../../../assets'
import socialMediaList from '../../../constants/social_media'
import './style.scss'

const { robotLogo, westerbergLogo } = images

const westerbergLinkClicked = () => {
  window.gtag_sendEvent('westerberg_click')
	window.fbq_customEvent('WesterbergClick')
}

const Footer = () => {
  const socialMediaItems = socialMediaList.map((item) =>
    <a href={item.href} target='_blank' rel='noreferrer' onClick={item.gtag} key={item.name}>{item.name}</a>
  )

  return (
    <section id='footer'>
      <Container>
        <Row className='align-items-end'>
          <Col lg={4} md={4}>
            {/*
            <a className='navbar-brand nb-2' href='index.html'>
              <b>Algoriffix.</b>
            </a>
            */}
            <Image className='brand' src={robotLogo} alt='Algoriffix Logo' fluid />
          </Col>
          <Col lg={5} md={6} className='ml-auto friendly-support'>
            <p>With friendly support of</p>
            <div className='westerberg-guitars'>
              <a href='https://www.westerbergguitars.com/' target='_blank' rel='noreferrer' onClick={westerbergLinkClicked}>
                <Image className='logo' src={westerbergLogo} alt='Westerberg Electric Guitars' fluid />
              </a>
              <div className='text'>ELECTRIC GUITARS</div>
            </div>
            {/*
            <form>
              <div className='form-group fg2'>
                <input type='email' className='form-control' id='exampleInputEmail1' placeholder='Subscribe Now' />
                <button type='submit' className='btn btn-primary'>
                  <i className='fa fa-paper-plane-o' aria-hidden />Send
                </button>
              </div>
            </form>
            */}
          </Col>
        </Row>
        <Row className='no-p2 pt-5'>
          <Col lg={3} sm={6} md={6} className='footer-text'>
            <h3>About Us</h3>
            <p>
              We believe that creativity knows no bounds.
              Our mission is to empower musicians, songwriters and producers by making artificial intelligence part of their creative process. 
              <a href='about_us'>More</a>
            </p>
          </Col>
          <Col lg={3} sm={6} md={6} className='footer-text'>
            <h3>Our Products</h3>
            <a href='#plugin'>Transkr V2</a>
            <a href='#download'>Free Trial</a>
            <a href='#pricing'>Buy License</a>
          </Col>
          <Col lg={3} sm={6} md={6} className='footer-text'>
            <h3>Follow Us</h3>
            {socialMediaItems}
          </Col>
          <Col lg={3} sm={6} md={6} className='footer-text'>
            <h3>Legal Documents</h3>
            <a href='privacy'>Privacy Policy</a>
            <a href='terms'>Terms and Conditions</a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Footer
