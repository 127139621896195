import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import './style.scss'

const aboutUsView = () => {
	window.gtag_sendEvent('about_us')
}

const AboutUs = () => {
  useEffect(() => {
    aboutUsView()
  }, [])

  return (
    <section id='about-us'>
      <Container className='about-us-text'>
        <h2>Executive Summary</h2>
        <p>
          Big Tech is competing for the number one spot in artificial general intelligence, 
          pouring hundreds of millions into a billion dollars valued market. 
          It also partners with a few hand-picked AI music technology startups to generate complete songs from a text prompt at the click of a button. 
          Already now, everybody can create musical artifacts without any musical knowledge. 
          But is it really that simple to replicate human craftmanship? 
          Although certain generative music AI technology can leave a layman in awe, 
          the value proposition of such services for those who make a living off their artistry is highly questionable. 
          Even more so, apart from raising questions around fair use and intellectual property, 
          such systems pose a direct threat to any musician by creating a thick fog of synthetic music with little originality and emotional involvement, 
          which makes it even harder to be noticed in an already extremely competitive business. 
          Eventually, it forces the musician to increase productivity at the cost of creativity and originality to stand a chance against trickery and machinery in a game of numbers.
        </p>
        <p>
          Understanding the fears of the artists, at Algoriffix, 
          we are building human-centered audio plug-ins, making use of digital signal processing, machine learning, and symbolic and weak artificial intelligence 
          to avoid the pitfalls of the cloud: all algorithms are made in-house and designed to run locally on a CPU in real time and with low latency. 
          By figuring out the fundamental elements of music, such as rhythm, tempo, dynamics, melody, harmony, and texture, 
          we seek to make possible a live musical conversation between human and agent as an augmented creative process. 
          To this end, we generate symbolic data that can be exchanged between the plug-in and the host via the MIDI protocol and the Standard MIDI File format. 
          As a result, the artist can increase output and keep the human imprint on music, which is the basis for an emotional connection with the listener.
        </p>
        <p>
          For any further inquiries, you can reach us at info@algoriffix.com.
        </p>
      </Container>
    </section>
  )
}

export default AboutUs
