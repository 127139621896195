import React from 'react'
import './style.scss'

const Preloader = () => (
	<div id='main-preloader' className='main-preloader semi-dark-background'>
		<div className='main-preloader-inner center'>
			<div className='preloader-percentage center'>
				<div className='object object_one' />
				<div className='object object_two' />
				<div className='object object_three' />
				<div className='object object_four' />
				<div className='object object_five' />
			</div>
			<div className='preloader-bar-outer'>
				<div className='preloader-bar' />
			</div>
		</div>
	</div>
)

export default Preloader
