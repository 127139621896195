import React from 'react'
import {
	Container, Row, Col, Image,
} from 'react-bootstrap'
// import 'pure-react-carousel/dist/react-carousel.es.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faVideo } from '@fortawesome/free-solid-svg-icons'
// import VideoPlayer from '../../VideoPlayer'
// import FormTitle from '../../common/FormTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAudio, faGuitar, faHandSpock, faMicrophone, faMusic, faSliders, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { images } from '../../../assets'
import './style.scss'

// const { audioPlugin, kvrAudio } = images
const { transkrV2Gui, fretboardGui } = images

const AudioPlugin = () => (
	<section id='plugin'>
		<Container>
			{/* <FormTitle text='Audio Plugin' /> */}
			<Row className='plugin-p' /* align-items-center' */>
				<Col lg={6} md={10} className='m-md-auto plugin-img' /*text-center'*/>
					<Image src={transkrV2Gui} alt='Transkr V2 GUI' fluid />
					{/*
					<div className='plugin-overlay'>
						<VideoPlayer url='https://youtu.be/pddT15Dd0tc'>
							<div className='plugin-button'>
								<FontAwesomeIcon icon={faVideo} />
							</div>
						</VideoPlayer>
					</div>
					*/}
				</Col>
				<Col lg={6} className='plugin-text'>
					<h2>Transkr V2 Plug-In</h2>
					<h3>Audio to MIDI Made Easy</h3>
					<p className='plugin-p-padding'>
						Turn your guitar into a MIDI controller and play fingerstyle like a boss
					</p>
					<p><FontAwesomeIcon icon={faMicrophone} />Real-time polyphonic note recognition</p>
					<p><FontAwesomeIcon icon={faMusic} />Beat, key, chord and melody tracking</p>
					<p><FontAwesomeIcon icon={faSliders} />Model customisation and fine-tuning</p>
					<p><FontAwesomeIcon icon={faGuitar} />Multiple instruments</p>
					<p><FontAwesomeIcon icon={faStopwatch} />Low latency</p>
					<p><FontAwesomeIcon icon={faFileAudio} />File-based audio to MIDI</p>
					<p><FontAwesomeIcon icon={faHandSpock} />Fretboard with guitar fingerings</p>
					{/*
					<Row className='align-items-center'>
						<p className='plugin-p-rate'>
							Rate it on
						</p>
						<a
							href='https://www.kvraudio.com/product/transcr-by-algoriffix'
							target='_blank'
							rel='noreferrer'
						>
							<img
								alt='KVR Audio'
								src={kvrAudio}
								height='125px'
							/>
						</a>
					</Row>
					*/}
				</Col>
			</Row>
			<Row className='plugin-fretboard'>
				<Col>
					<Image src={fretboardGui} alt='Fretboard GUI' fluid />
				</Col>
			</Row>
		</Container>
	</section>
)

export default AudioPlugin
