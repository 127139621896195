import { useState } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { Cookies } from 'react-cookie-consent'
import './style.scss'

const cookieName = '_arx_hide_update_224'

const Promo = () => {
  const [show, setShow] = useState(!Cookies.get(cookieName))

  const handleDismiss = () => {
    Cookies.set(cookieName, true)
    setShow(false)
  }

  return (
    <Alert className='promo-container' variant='' show={show}>
      <div className='promo-content'>
        <Alert.Heading>New keyboard feature</Alert.Heading>
        <p>
          Version 2.2.4 of the <Alert.Link href='#plugin'>Tranksr V2</Alert.Link> plug-in is now available for FREE trial. 
          Download it from <Alert.Link href='#download'>here</Alert.Link> for macOS or Windows.
        </p>
      </div>
      <div className=''>
        <Button className='dismiss-button' variant='' onClick={handleDismiss}>
          Dismiss
        </Button>
      </div>
    </Alert>
  )
}

export default Promo
