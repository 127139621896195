import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
// import { /* faPaypal, */ faStripe } from '@fortawesome/free-brands-svg-icons'
import { images } from '../../../assets'
import './style.scss'

const { scanToPay } = images

// const stripePaymentLink = 'https://buy.stripe.com/eVaaEY1Iu8mw2Gs28d' // SEK
const stripePaymentLink = 'https://buy.stripe.com/9AQfZifzkeKUbcYfZ4' // USD
// const payPalPaymentLink = 'https://www.paypal.com/ncp/payment/2V2BQMXS78WEJ'
// const payPalPaymentLink = 'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=CPUKEQ2DFS6RY'

const initiateCheckout = () => {
	window.gtag_sendEvent('begin_checkout', {
		currency: 'USD',
		value: 39.00,
		items: [
			{
				item_name: 'Transkr V2 License'
			}
		]
	})

	window.fbq_standardEvent('InitiateCheckout')
}

const stripePaymentLinkClicked = () => {
	initiateCheckout()
}

/*
const payPalPaymentLinkClicked = () => {
	initiateCheckout()
}
*/

const PricingTable = () => (
	<section id='pricing'>
		<Container>
			<Row className='pricing-table'>
				<Col lg={4} sm={6} className='pricing-text'>
					<p className='pricing-call-to-action'>
						Weary of overpriced audio-to-MIDI gear and software? Buy a Transkr V2 license and save hundreds of dollars today!
					</p>
					<div className='pricing-check-marks'>
						<p><FontAwesomeIcon icon={faCheck} />No nag screens</p>
						<p><FontAwesomeIcon icon={faCheck} />No time limitations</p>
						<p><FontAwesomeIcon icon={faCheck} />No recurring costs</p>
						<p><FontAwesomeIcon icon={faCheck} />Lifetime free updates</p>
					</div>
				</Col>
				<Col lg={4} sm={6} className='pricing-buy-buttons text-center'>
					{/* <p className='pricing-title'>Buy Transkr V2 License</p> */}
					<p className='pricing-title'>Transkr V2 License</p>
					<p className='pricing-price'>USD 39</p>
					{/* <p><Link to={stripePaymentLink} onClick={stripePaymentLinkClicked}><FontAwesomeIcon icon={faStripe} /></Link></p> */}
					<p><Link to={stripePaymentLink} onClick={stripePaymentLinkClicked}>Buy</Link></p>
					{/* <p><Link to={payPalPaymentLink} onClick={payPalPaymentLinkClicked}><FontAwesomeIcon icon={faPaypal} /></Link></p> */}
				</Col>
				<Col lg={4} sm={6} className='pricing-scan-to-pay text-center'>
					<Image src={scanToPay} alt='Scan to Pay' className='pricing-qr-code' fluid />
				</Col>
			</Row>
		</Container>
	</section>
)

export default PricingTable
