import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import './style.scss'

const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

const BackToTop = () => (
	<div className='backtotop' onClick={scrollToTop}>
		<FontAwesomeIcon icon={faAngleUp} />
	</div>
)

export default BackToTop
